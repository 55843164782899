.Header {
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  background: #1867c4;
  color: white;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
}

.Header-navbar-start {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
}
.Header-navbar-start-icon {
  width: 6.64rem;
}
.Header-navcontainer {
  display: flex;
}
.Header-navbar-center {
  display: flex;
  align-items: center;
}

.Header-navbar-center :where(li) {
  gap: 0.75rem;
  padding: 0.75rem 1rem;
  font-size: 1rem;
}
.Header-navbar-end {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.Header-navbar-btn {
  display: flex;
  align-items: center;
  color: black;
  font-size: 1rem;
  background-color: #f0b829;
  padding: 1rem 1.25rem;
  border-radius: 0.5rem;
  margin-right: 0.5rem;
  font-family: "SUIT";
  font-weight: 700;
  cursor: pointer;
}

.Header-menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: max-content;
}

.Header-navbar-item {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.Header-navbar-Link {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin: 0rem 4rem;
}
.Header-end-menu {
  display: none;
}
@media (max-width: 900px) {
  .Header {
    justify-content: space-between;
  }

  .Header-menu {
    position: absolute;
    top: 7rem;
    right: 0;
    overflow: hidden;
    flex-direction: column;
    transition: 0.3s ease-in-out;
    transform: scale(0);
    transform-origin: top;
  }
  .Header-menu-open {
    height: max-content;
    transform: scale(1);
  }
  .Header-end-menu {
    display: flex;
    justify-self: flex-end;
    align-self: center;
    padding: 0 1rem;
  }
}
