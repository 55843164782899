.Tokenomics {
    display: flex;
    background: black;
    color: white;
    justify-content: center;
    padding-top: 4rem;
}
.Tokenomics-container {
    display: flex;
    flex-direction: column;
    max-width: 50%;
    padding: 2.5rem;
}
.Tokenomics-title {
    font-size: 4rem;
    font-weight: 900;
    color: #efc24f;
    text-shadow: -2px -2px 0 white, 2px -2px 0 white, -2px 2px 0 white, 2px 2px 0 white;
    margin: 0;
}

.Tokenomics-subtitle {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 2.2rem;
    margin: 0;
}
.Tokenomics-divider {
    border: 1px #efc24f solid;
    margin: 2rem 0;
}

.Tokenomics-content {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 2.2rem;
}
.Tokenomics-image {
    background: #f0b829;
}
.Tokenomics-pepe {
    width: 34.08rem;
}
@media (width < 900px) {
    .Tokenomics {
        display: block;
    }
    .Tokenomics-container {
        max-width: 100%;
    }
}
