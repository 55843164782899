.About {
    display: flex;
    background: black;
    color: white;
    justify-content: center;
    padding-top: 3rem;
}

.About-pepe {
    width: 38.2rem;
}
.About-container {
    display: flex;
    flex-direction: column;
    max-width: 50%;
    padding: 2.5rem;
}
.About-title {
    font-size: 7.2rem;
    font-weight: 900;
    text-shadow: -3px -3px 0 #1867c4, 3px -3px 0 #1867c4, -3px 3px 0 #1867c4, 3px 3px 0 #1867c4;
    margin: 0;
}

.About-divider {
    border: 1px #1867c4 solid;
    margin: 2rem 0;
}

.About-content {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 2.2rem;
}

@media (width < 900px) {
    .About {
        display: block;
    }

    .About-container {
        max-width: 100%;
    }
}
