.Home {
  display: flex;
  background: #1867c4;
  color: white;
  justify-content: center;
  padding-top: 4rem;
}

.Home-pepe {
  width: 39.12rem;
}
.Home-container {
  display: flex;
  flex-direction: column;
  max-width: 50%;
  padding: 2.5rem;
}
.Home-divider {
  border: 1px white solid;
  margin: 2rem 0;
}

.Home-btns {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.Home-btn {
  color: black;
  background: #f0b829;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 2rem;
  font-size: 1rem;
  font-weight: 700;
  flex-grow: 1;
  text-align: center;
}

.Home-iconbtns {
  margin: 3rem 0;
}

.Home-iconbtns a:not(:last-child) {
  margin-right: 3rem;
  max-width: 50%;
}
@media (max-width: 432px) and (min-width: 372px) {
  #second-heading {
    font-size: 6rem !important;
  }
}

@media (max-width: 371px) {
  #second-heading {
    font-size: 4.8rem !important;
  }
}

@media (width < 900px) {
  .Home {
    display: block;
  }
  .Home-container {
    max-width: 100%;
  }

  .Home-container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: 2.5rem;
  }
}
