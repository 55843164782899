.Staking-Home {
  display: flex;
  flex-direction: column;
  background: #1867c4;
  color: white;
  align-items: center;
  padding-top: 3rem;
  height: 140%;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 3rem 0;
}

.title {
  font-size: 24px;
  font-weight: 700;
}

p {
  white-space: pre;
}

.balance {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.balance-p {
  font-size: 12px;
  text-align: right;
  cursor: pointer;
}

.staking-items {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 2px solid white;
  border-radius: 1.5rem;
  max-width: 340px;
}

.staking-container {
  gap: 2rem;
  display: flex;
}

.token-img-1 {
  width: 52px;
  height: 52px;
}

.token-img-2 {
  width: 32px;
  height: 32px;
  position: relative;
  right: 15px;
  top: 18px;
  border-radius: 50%;
}

.pair-container {
  display: flex;
  align-items: center;
}

.description {
  margin: 1rem 0;
}

.details-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}

.button-container {
  display: flex;
  gap: 1rem;
}

.modal {
  background-color: white;
  min-width: 270px;
  border-radius: 6px;
  padding: 0rem 3rem 3rem 3rem;
}

#modal-title {
  margin: 2rem 0;
}

.modal-content {
  display: flex;
  flex-direction: column;
}

.pool-name {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

.pool-details {
  margin: 0;
  font-size: 12px;
}
@media screen and (max-width: 750px) {
  .staking-container {
    flex-direction: column;
  }
}

@media screen and (max-width: 400px) {
  .staking-items {
    padding: 1rem;
  }
}
